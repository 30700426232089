// Load background images
jQuery('.load-bg-img').each(function(i, obj) {
  var item = jQuery(this);
  var bgImg = new Image();
  bgImg.onload = function() {
    item.css('background-image', 'url(' + bgImg.src + ')');
    item.addClass('loaded');
  };


  if (jQuery(document).width() > 800) {
    bgImg.src = item.attr('data-bg');
  } else if (jQuery(document).width() > 400) {
    bgImg.src = item.attr('data-bg-small');
  } else {
    bgImg.src = item.attr('data-bg-tiny');
  }
});



jQuery('body').on("click", ".dropdown-menu", function(e) {
  jQuery(this).parent().is(".show") && e.stopPropagation();
});


// Don't allow clicks on certain links
jQuery(document).ready(function() {
  jQuery('.no-click').on('click', function() {
    return false;
  });
});




// Bio popups
jQuery(document).ready(function() {
  jQuery('.modal-trigger').on('click', function() {
    var id = jQuery(this).attr('id');
    console.log(jQuery('#' + id + '-content .mtitle').html());
    //jQuery('#' + id + ' .mtitle').html();
    jQuery('.modal .modal-title').html(jQuery('#' + id + '-content .mtitle').html());
    jQuery('.modal .modal-body').html(jQuery('#' + id + '-content .mbody').html());

    jQuery('.modal').modal('show');
  });
});

jQuery(document).ready(function() {
  jQuery('.answer.collapse').on('hide.bs.collapse', function() {
    jQuery(this).parent().find('h3').removeClass('showing');
  });

  jQuery('.answer.collapse').on('show.bs.collapse	', function() {
    jQuery(this).parent().find('h3').addClass('showing');
  })

  jQuery('.nav.collapse').on('hide.bs.collapse', function() {
    jQuery('.navbar-container').removeClass('show');
    jQuery('.navbar').removeClass('show');
    jQuery('.navbar-toggler').removeClass('show');
    jQuery('html').removeClass('show-nav');
    jQuery('body').removeClass('no-scroll');
    setTimeout(function() {
      jQuery('.navbar-container').removeClass('showing');
      jQuery('.navbar-toggler').removeClass('showing');
      jQuery('html').removeClass('showing-nav');
    }, 100);
  });

  jQuery('.nav.collapse').on('show.bs.collapse	', function() {
    jQuery('.navbar-container').addClass('showing');
    jQuery('.navbar').addClass('showing');
    jQuery('.navbar-toggler').addClass('showing');
    jQuery('html').addClass('showing-nav');
    jQuery('body').addClass('no-scroll');


    setTimeout(function() {
      jQuery('.navbar-container').addClass('show');
      jQuery('.navbar-toggler').addClass('show');
      jQuery('html').addClass('show-nav');
    }, 100);
  });

  jQuery('.search-form.collapse').on('show.bs.collapse	', function() {

    setTimeout(function() {
      jQuery('.search-form input.search-field').focus();
    }, 100);
  });


  // Search form toggle
  jQuery('#website-search-toggle').on('click', function() {
    jQuery(this).addClass('show');
    jQuery('#catalog-search').addClass('show');
    jQuery('#website-search-toggle').removeClass('show');
    jQuery('#website-search').removeClass('show');
    jQuery('#website-search-toggle input').prop('checked', true);
    jQuery('#catalog-search-toggle input').prop('checked', false);
  });
  jQuery('#catalog-search-toggle').on('click', function() {
    jQuery(this).addClass('show');
    jQuery('#website-search').addClass('show');
    jQuery('#catalog-search-toggle').removeClass('show');
    jQuery('#catalog-search').removeClass('show');
    jQuery('#catalog-search-toggle input').prop('checked', true);
    jQuery('#website-search-toggle input').prop('checked', false);
  });


});


// If we have a hero Image
if (jQuery('.page-title').length) {
  var bgImg = new Image();
  bgImg.onload = function() {
    jQuery('.page-title').css('background-image', 'url(' + bgImg.src + ')');
    jQuery('.page-title').addClass('inplace');
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = jQuery('.page-title').attr('data-bg');
  } else if (jQuery(document).width() > 400) {
    bgImg.src = jQuery('.page-title').attr('data-bg-small');
  } else {
    bgImg.src = jQuery('.page-title').attr('data-bg-tiny');
  }
}


//
// Open external links in a new window
//
jQuery('a').each(function() {
  var a = new RegExp('/' + window.location.host + '/');
  if (!a.test(this.href)) {
    jQuery(this).click(function(event) {
      if (!this.href.match(/^mailto\:/)) {
        event.preventDefault();
        event.stopPropagation();
        window.open(this.href, '_blank');
      }
    });
  }
});



/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.marry-widows h1, .marry-widows h2, .marry-widows h3, .marry-widows p').each(function() {
          var string = $(this).html();
          string = string.replace(/ ([^ ]*)$/, '&nbsp;$1');
          $(this).html(string);
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.




function getScrollTop() {
  if (typeof pageYOffset !== 'undefined') {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = (D.clientHeight) ? D : B;
    return D.scrollTop;
  }
}



function getHeaderTop(div) {
  var winTop;
  if (!div) {
    winTop = jQuery(window).scrollTop();
  } else {
    winTop = jQuery(div).offset().top;
  }
  var headerTop = winTop;
  if (headerTop > headerHidden) {
    headerTop = headerHidden;
  } else if (headerTop < 0) {
    headerTop = 0;
  }
  return headerTop;
}

// State of the header.
var top_stick = true;
var darker_nav = false;




// Update the navigation based on the width of the viewport.
function updateNav() {
  //
  // Update header background color.
  //
  var from_top = jQuery(window).scrollTop();
  if (from_top > 10 && !darker_nav) {
    jQuery(".header").addClass('scrollnav');
    darker_nav = true;
  } else if (from_top <= 10 && darker_nav) {
    jQuery(".header").removeClass('scrollnav');
    darker_nav = false;
  }
}


var sticky_list;
var sticky_list_width;
var sticky_list_stop_point;

function windowResizeTasks() {
  if (jQuery('.sticky-list').length > 0) {
    sticky_list = jQuery('.sticky-list').offset().top;
    sticky_list_width = jQuery('.sticky-list').width();
    sticky_list_height = jQuery('.sticky-list').height();
    sticky_list_stop_point = (jQuery('.scrollspy').offset().top + jQuery('.scrollspy').height()) - sticky_list_height;
    sticky_list_stop_point = 2000;

    //console.log(sticky_list_stop_point);

    jQuery('.sticky-list').css('width', sticky_list_width);
  }
}




jQuery(document).ready(function() {
  jQuery('html').addClass('js');

  //windowResizeTasks();

  // Remove 300ms delay when tapping on touch devices.
  jQuery(function() {
    FastClick.attach(document.body);
  });

  updateNav();
  jQuery(window).scroll(jQuery.throttle(65, updateNav));
  jQuery(window).on("debouncedresize", function(event) {
    // Your event handler code goes here.
    windowResizeTasks();
    updateNav();
  });



  jQuery('a.list-group-item').click(function(e) {
    e.preventDefault();

    event.preventDefault();
    //calculate destination place
    var dest = 0;
    if (jQuery(this.hash).offset().top > jQuery(document).height() - jQuery(window).height()) {
      dest = jQuery(document).height() - jQuery(window).height();
    } else {
      //dest = jQuery(this.hash).offset().top;
      //dest = jQuery(this.hash).position().top;
      dest = jQuery(this.hash).get(0).offsetTop;

      console.log('Skipping to: ' + dest);
    }

    jQuery('.scrollspy').scrollTop(dest);
    jQuery('a.list-group-item').removeClass('active');
    jQuery('a.list-group-item').removeClass('active');
    jQuery(this).addClass('active');

    setTimeout(function() {
      jQuery(this).addClass('active');
    }, 20);

  });


});








String.prototype.trim = function() {
  return this.replace(/^\s*|\s*$/g, '');
}

function ProcessInput(e)

{

  if (window.event) // IE

    keyNum = e.keyCode;

  else if (e.which) // Netscape/Firefox/Opera

    keyNum = e.which;

  if (keyNum == 13)

  {

    e.returnValue = false;

    e.cancelBubble = true;

    DoKeywordSearch();

    return false;

  }


  return true;

}



function DoKeywordSearch()

{

  if (document.getElementById('textboxTerm').value.trim() != '')

    window.location =
    'https://catalog.onlib.org/polaris/view.aspx?keyword=' +
    escape(document.getElementById('textboxTerm').value);

  else

    document.getElementById('textboxTerm').focus();

}